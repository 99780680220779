<template>
    <div class="container">
        <let-it-snow
            v-bind="snowConf"
            :show="show"
        />
        <transition name="flip">
            <router-view />
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'ChristmasCard2020',
        data() {
            return {
                snowConf: {
                    windPower: 0,
                    speed: 0.005,
                    count: 200,
                    size: 15,
                    opacity: 1,
                    images: ['https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/snow.png']
                },
                show: false
            };
        },
        mounted() {
            this.show = true;
        }
    };
</script>
<style
    lang="sass"
>
    html, body, .website-container
        background-color: $red !important
        background: unset

        .container
            justify-content: center
            display: flex
            width: 100vw

            .snow-wrap
                z-index: 1

            .card
                z-index: 3
                margin: 5rem 0
                padding: 2rem
                width: 60%
                max-width: 700px
                background-color: $light
                background-image: url('./assets/fabric_texture.png')
                background-repeat: repeat
                border-radius: 15px

                img
                    height: auto

                video
                    max-width: 100% !important
                    height: auto


            @media(max-width: 500px)
                .card
                    width: 80% !important
                    margin: 5px 0 !important

                    h1.override-home-card-header, h1.detail-header, h1.highlight-header
                        font-size: 2rem !important

                    h2.override-home-card-header
                        font-size: 1.2rem !important

                    a.christmas-button
                        font-size: 2rem
                        text-align: center

                    .hero-image
                        max-width: 90% !important

</style>
